@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Great+Vibes&display=swap');

body {
  color: #fff;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased; /* For Webkit browsers */
  -moz-osx-font-smoothing: grayscale; /* For Firefox */
}
.coin-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #d4af37;
  padding: 2px;
  margin: 2px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

sales-page p,
.features-benefits ul li {
  font-size: 16px;
  line-height: 1.5;
}

.how-it-works .table {
  margin: 0 auto;
  padding: 16px;
  border: none;
}

.how-it-works .table .profitSecured {
  color: #d4af37;
}

.sales-page section {
  padding: 60px 0;
}

.sales-page section h2 {
  margin-bottom: 20px;
  color: #d4af37;
  text-align: center;
}

.sales-page h3 {
  color: #e0321c;
  font-family: 'DM Serif Display', serif;
}

.fullscreen-bg {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  min-height: 70vh;
  background-position: bottom center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-box {
}

.cta-box button {
  width: 100%;
}

.start-today {
  background-color: #111;
  padding: 20px;
  text-align: center;
}

.cta-box h1 {
  font-size: 70px;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #d4af37;
  border-bottom: 2px solid #d4af37;
  font-family: 'Great Vibes', cursive;
  font-weight: 300;
}

.cta-box h1 div {
  font-family: 'DM Serif Display', serif;
  font-size: 46px;
  color: #fff;
}

.cta-box p {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 300; /* Makes the text thinner */
  line-height: 1.5; /* Increases the space between lines of text */
}

.sales-page main {
  padding: 20px;
}

.sales-page h2 {
  color: #d4af37;
  padding-bottom: 5px;
  font-family: 'Great Vibes', cursive;
  font-size: 50px;
  font-weight: 300;
}

.sales-page section {
  margin-bottom: 40px;
}

.why-choose-us {
  text-align: center;
}

.why-choose-us p {
  text-align: left;
}

.start-today {
  background-size: cover;
  background-position: center;
}

.start-today div {
  max-width: 800px;
  margin: 0 auto;
}

.sales-page section.start-today div h2 {
  color: #d4af37;
  font-size: 72px;
  font-family: 'Great Vibes', cursive;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
}

.sales-page section.start-today div h2 span,
.sales-page section.start-today div h2 div {
  color: #fff;
  font-family: 'DM Serif Display', serif;
  font-weight: 400;
  font-style: italic;
}

.sales-page section.start-today div h2 div {
  display: block;
  font-size: 62px;
}

.sales-page section.features-benefits,
section.past-performance {
  background: #000 !important;
  margin-bottom: 0;
  padding-top: 94px;
}

section.past-performance {
  margin: 0 auto;
  text-align: center;
}

section.past-performance .past-perf-numbers h2 {
  color: #fff;
  font-family: inherit;
  font-size: 22px;
}

section.past-performance .past-perf-numbers .ui.steps .step.active {
  background-color: #d4af3785;
}

section.past-performance .past-perf-numbers em {
  color: #6a6866;
}
section.trading-pairs ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: space-between;
}

section.trading-pairs ul li {
  /* flex: 1 0 auto; */
  margin: 10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
}

section.trading-pairs ul li img {
  width: 40px;
  height: 40px;
  margin-right: 4px;
  background-color: #dbdbdb !important;
  border-radius: 50%;
  padding: 6px;
}

section.past-performance .PortfolioChart .tv-lightweight-charts {
  margin: 0 auto;
}

.features-benefits ul {
  list-style-type: none;
  padding: 0;
}

.features-benefits ul li {
  margin: 10px 0;
}

.testimonials blockquote {
  border-left: 4px solid #d4af37;
  padding-left: 10px;
  color: #e0321c;
}

.start-today .cta-button:hover {
  background-color: #e0321c;
  color: #fff;
}

.faq-item h3 {
  color: #d4af37;
}

footer {
  text-align: center;
  padding: 10px;
  background-color: #111;
  border-top: 2px solid #d4af37;
  position: fixed;
  width: 100%;
  bottom: 0;
}

@media only screen and (max-width: 768px) {
  section.trading-pairs ul {
    justify-content: center;
    max-height: 300px;
    overflow-y: auto;
  }

  .sales-page main {
    padding: 0px;
  }
}
