.ui.toggle.checkbox input:not(:checked) ~ .box:before,
.ui.toggle.checkbox input:not(:checked) ~ label:before {
  background-color: #e0e0e0 !important;
}

.ui.statistic .value {
  font-size: 1.2em;
  font-weight: bold;
}

.ui.statistic .label {
  font-size: 0.9em;
  color: rgba(228, 226, 223, 0.6);
}

.ui.container {
  width: 100%;
}

.Header.Navigation {
  width: 100%;
  padding: 15px;
  border-color: #e7e7e7;
  display: inline-block;
  background-color: #f8f8f8;
  margin-bottom: 20px;
}

.header .logo img {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  border-radius: 50%;
  border-top-right-radius: 0;
}

.Navigation ul {
  float: left;
  margin: 0;
}

.login-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.login-container {
  display: flex;
  position: relative;
  padding: 4em;
  width: 100%;
  justify-content: center;
}
.login-container img {
  background: #292c2f;
  border-radius: 50%;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  /* also try this #181a1a; */
}

.login-container button {
  border-radius: 50% !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.backdrop-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  backdrop-filter: sepia(0.7) blur(0.8px);
}

.tableContainer .ui.table:first-child {
  margin: 1em 0;
}

.Navigation ul li {
  float: left;
  position: relative;
  display: block;
  padding: 5px;
}

.Notifications {
  padding: 5px;
  order: 3;
  min-width: 115px;
  display: flex;
  justify-content: flex-end;
}

.Notifications > span {
  float: left;
  padding-right: 8px;
  color: white;
}

.notification-items {
  display: block;
}

i.red.icon {
  color: #db2828 !important;
  position: absolute;
  top: -10px;
  right: 15px;
}

.ui.dropdown > .dropdown.icon {
  color: white;
}

.nodisplay {
  display: none !important;
}

.textcenter {
  text-align: center;
}

.cards {
  background: #e1e1e1;
  text-align: center;
  margin: 0px;
  min-height: 170px;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.cards > h2 {
  font-size: 16px;
  padding-top: 15px;
  color: #d4af37;
}

.cards h5 {
  color: #a9a5a5;
}

.ui.section.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ui.form {
  font-size: 1rem;
  padding: 0 15px;
}

.ui.form .fields {
  margin: 0 -0.5em 0.5em;
}

/*DASHBOBARD TABLE STYLES START*/
.coinholdings {
  margin: 15px;
}
.coinholdings .ui.collapsing.table {
  padding: 0.3em;
  margin: auto !important;
}
.coinholdings .form-control,
.coinholdings table {
  width: 100%;
}

.floatRight {
  float: right;
}
.infoHeader {
  background-color: transparent !important;
}
.coinholdings .form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

table#coins {
  width: 100%;
  border: 1px solid #ccc;
  padding: 0;
}

table#coins tr {
  list-style: none;
  border-bottom: 1px solid #ccc;
}

table#coins tbody tr td:first-child i {
  font-size: 9px;
}

table#coins tbody tr td:first-child a {
  float: right;
}

table#coins thead tr:first-child td {
  font-weight: bold;
  text-align: center;
}

table#coins td {
  padding: 5px 15px;
  display: inline-block;
  border-right: 1px solid #ccc;
  width: 10%;
}
.coinholdings tr td {
  position: relative;
}

table#coins td:last-child {
  border-right: 0;
}

.coinholdings tr td i.perc {
  position: absolute;
  left: -20px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  background: #fff;
  color: #000;
  padding: 5px;
  height: 30px;
  width: 30px;
  top: -1px;
  box-shadow: 1px 1px 0px 0px;
  text-align: center;
}

.freeCoinsAll a,
.freeCoinsSome a,
.freeCoinsNonePB a {
  color: #fff;
}

.freeCoinsAll {
  background: #0a8415;
  color: #fff;
}

.freeCoinsSome {
  background: #0c87d0;
  color: #fff;
}

.freeCoinsNone {
  background: #fff;
  color: #000;
}
.coinholdings tr td {
  position: relative;
}
table#coins tr {
  list-style: none;
  border-bottom: 1px solid #ccc;
}

table#coins td {
  padding: 5px 15px;
  display: inline-block;
  border-right: 1px solid #ccc;
  width: 10%;
  border-bottom: 1px solid #ccc;
}
.coinholdings tr input {
  border: none;
  width: 100%;
}

.coinholdings tr td button {
  text-align: center;
  float: none;
  display: inline-block;
  margin: 0 auto;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
/*DASHBOBARD TABLE STYLES END*/

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.vmiddle {
  vertical-align: middle !important;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #0000005e;
  color: #fff;
  text-align: center;
  padding: 1px 0;
  border-radius: 6px;
  position: absolute;
  top: -11px;
  z-index: 999999;
  font-size: 9px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.noPadding {
  padding: 0 !important;
}

.noMargin {
  margin: 0 !important;
}

.noBorder {
  border: 0 !important;
}

.marginAuto {
  margin: auto !important;
  display: block !important;
}

.marginTop {
  margin-top: 8px !important;
}

.marginBottom {
  margin-bottom: 16px !important;
}

.width100 {
  width: 100px;
}

.clndr-calendars-segment.clndr-floating {
  bottom: 0;
}

header.header {
  top: 0;
  width: 100%;
  box-shadow: 0 4px 20px hsla(207, 24%, 35%, 0.1);
  background-color: #000000 !important;
  z-index: 999;
}

.header.infoHeader {
  z-index: 1;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
}

.logo {
  order: 1;
}

.logo a {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.logo a span {
  color: #8739fa;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  order: 2;
  list-style: none;
}

.ui.vertical.divider {
  z-index: 0;
}

ul.menu li a {
  display: block;
  padding: 7px 15px;
  font-weight: 500;
  transition: 0.2s all ease-in-out;
  color: #fff;
  list-style: none;
}

.menu:hover a {
  opacity: 0.4;
}

.menu a:hover {
  opacity: 1;
  color: #fff;
}

.menu-icon {
  display: none;
}

#menu-toggle {
  display: none;
}

#menu-toggle:checked ~ .menu {
  transform: scale(1, 1);
}

.ui.tertiary.segment {
  background-color: transparent;
}

.backtest-multi .ui.steps:first-child {
  margin-top: 14px;
}

.menu.transition.left.visible {
  max-height: 80vh;
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  body {
    background-size: contain !important;
  }
  header.header {
    position: sticky;
  }

  .header .logo img {
    width: 60px;
    height: 60px;
    position: relative;
    top: 0;
    border-radius: 0;
  }

  .dashboardContainer .column {
    padding-top: 0;
  }
  .hide-on-mobile {
    display: none !important;
  }

  .no-padding-mobile {
    padding: 0 !important;
  }

  .coinholdings {
    margin: 15px 0;
  }

  .menu.transition.left.visible {
    left: 50% !important;
    transform: translateX(-75%);
  }

  th#currentPrice {
    display: none !important;
  }

  .coinholdings .ui.collapsing.table {
    padding: 0;
    border: 0;
  }

  .ui.collapsing.table {
    width: 100%;
  }
  .ui.table:not(.unstackable) tr {
    width: inherit !important;
    text-align: center;
  }

  .ui.table:not(.unstackable) thead tr:first-child {
    /* display: none !important; */
  }
  .ui.table:not(.unstackable) thead tr:last-child {
    font-size: 11px;
  }

  .ui.table:not(.unstackable) tbody tr td img.coin-icon {
    display: none;
  }

  .ui.icon.input {
    margin-bottom: 0 !important;
  }

  .cards {
    min-height: auto;
  }

  .ui.table:not(.unstackable) tr {
    display: flex !important;
    position: relative;
  }

  body .coinholdings table tbody tr td,
  .ui.table:not(.unstackable) tr > td,
  .ui.table:not(.unstackable) tr > th {
    display: block !important;
    width: -webkit-fill-available !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .coinholdings table td#currentPrice span.floatRight {
    display: none;
  }

  .coinholdings table td#rank {
    width: 100px !important;
  }
  .coinholdings table td#alerts {
    position: absolute;
    width: -webkit-fill-available !important;
    bottom: 0;
    padding: 0 !important;
  }

  .coinholdings table td#alerts > div {
    height: 5px !important;
    opacity: 0.7;
  }

  .coinSettingForm .ui.container.stackable.four.column.grid {
    margin: 0 auto !important;
  }
  .coinSettingForm .ui.stackable.grid > .column:not(.row) {
    width: 50% !important;
  }

  .login-container {
    flex-direction: column-reverse;
    padding: 1em 2em;
    align-items: center;
  }
  .login-container button {
    padding-bottom: 0;
    margin-bottom: 1em;
    border-radius: 0 !important;
  }
  .login-container img {
    background: transparent;
    /* also try this #181a1a; */
  }

  .ui.stackable.grid > .column:not(.row).no-padding-mobile {
    padding: 0 !important;
    margin: 0 !important;
  }

  /* Add this to handle the card spacing specifically */
  .ui.stackable.grid > .column:not(.row) .cards {
    margin: 0.25rem 0;
  }

  /* Add this new style for alerts header */
  .coinholdings .ui.table:not(.unstackable) thead tr th[data-id='alerts'] {
    display: none !important;
    width: 0 !important;
    padding: 0 !important;
    border: none !important;
  }
}

@media only screen and (max-width: 950px) {
  .coinholdings table {
    width: 100% !important;
  }

  .coinholdings table thead {
    display: none;
  }

  ul.menu {
    flex-direction: column;
    background-color: #151418;
    align-items: start;
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
    z-index: 1;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    margin: 0;
    list-style: none;
    padding: 16px;
  }

  ul.menu a {
    margin-left: 12px;
  }

  ul.menu li {
    margin-bottom: 10px;
  }

  .menu-icon {
    display: block;
    color: #fff;
    font-size: 28px;
    cursor: pointer;
  }
}
